import { __assign, __rest } from "tslib";
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Request from '@websky/core/src/Modules/Exchange/components/Exchange/Request/Request';
import Modal from '@websky/core/src/Modal';
import ResultsError from '@websky/core/src/ResultsError';
import Button from '@websky/core/src/Button';
import { useToggleable } from '@websky/core/src/hooks';
import { getSelectedPassengerIds } from '@websky/core/src/Modules/Exchange/store/passengers/selectors';
import { getOrderId } from '@websky/core/src/Checkout/store/order/selectors';
import { useGetExchangePriceQuery } from '@websky/graphql';
import css from './ExchangeRequest.css';
var ExchangeRequest = function (_a) {
    var _b, _c;
    var flightId = _a.flightId, props = __rest(_a, ["flightId"]);
    var t = useTranslation('Exchange').t;
    var _d = useToggleable(false), isOpen = _d.isOpen, open = _d.open, close = _d.close;
    var orderId = useSelector(getOrderId);
    var selectedPassengersIds = useSelector(getSelectedPassengerIds);
    var exchangePriceVariables = {
        params: {
            flightId: flightId,
            orderId: orderId,
            passengers: selectedPassengersIds.map(function (id) { return +id; })
        }
    };
    var _e = useGetExchangePriceQuery({
        fetchPolicy: 'no-cache',
        variables: exchangePriceVariables
    }), data = _e.data, loading = _e.loading, error = _e.error;
    useEffect(function () {
        if (error) {
            open();
        }
    }, [error]);
    var newFlightId = ((_c = (_b = data === null || data === void 0 ? void 0 : data.ExchangePrice) === null || _b === void 0 ? void 0 : _b.exchangeVariantPriceInfo) === null || _c === void 0 ? void 0 : _c[0].flightId) || flightId;
    var prices = React.useMemo(function () {
        var _a, _b, _c, _d;
        if ((_b = (_a = data === null || data === void 0 ? void 0 : data.ExchangePrice) === null || _a === void 0 ? void 0 : _a.exchangeVariantPriceInfo) === null || _b === void 0 ? void 0 : _b[0]) {
            var exchangePrices = data.ExchangePrice.exchangeVariantPriceInfo[0];
            return {
                priceToPay: exchangePrices.priceToPay,
                charges: {
                    amount: +((_c = exchangePrices.agencyChargesForExare) === null || _c === void 0 ? void 0 : _c.amount) + +((_d = exchangePrices.exchangeAirlinePenalty) === null || _d === void 0 ? void 0 : _d.amount),
                    currency: exchangePrices.priceToPay.currency
                }
            };
        }
        return null;
    }, [data === null || data === void 0 ? void 0 : data.ExchangePrice]);
    var onCloseHandler = function () {
        close();
        props.goToFlights();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Request, __assign({}, props, { flightId: newFlightId, exchangePrices: prices, isLoading: loading })),
        error && (React.createElement(Modal, { classes: {
                paperScrollBody: css.paperScrollBody
            }, maxWidth: "sm", open: isOpen, onClose: onCloseHandler },
            React.createElement(ResultsError, { header: t('An error has occurred'), body: React.createElement("div", null,
                    React.createElement("p", null, error.message),
                    React.createElement(Button, { onClick: onCloseHandler }, t('Confirm'))) })))));
};
export default ExchangeRequest;
