import { __assign } from "tslib";
import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { LocationType } from '@websky/core/src/types';
import { useIsMobile } from '@websky/core/src/utils';
import LocationComponent, { IGNORE_CLICKOUTSIDE_CLASS } from '@websky/core/src/SearchForm/components/SearchForm/Segments/Segment/Location/Location';
import Value from '@websky/core/src/SearchForm/components/SearchForm/Segments/Segment/Location/Value/Value';
import css from './Location.css';
var switcherIcon = (React.createElement("svg", { width: "11", height: "15", viewBox: "0 0 11 15", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3.99 15L0 11L3.99 7V10H11V12H3.99V15ZM0 3H7.01V0L11 4L7.01 8L7.01 5H0L0 3Z", fill: "currentColor" })));
var Location = function (props) {
    var className = props.className, type = props.type, locations = props.locations, withMultiCity = props.withMultiCity, onSwap = props.onSwap;
    var t = useTranslation('SearchForm').t;
    var location = locations[type];
    var isMobile = useIsMobile();
    var onSwapHandler = function (e) {
        e.stopPropagation();
        onSwap();
    };
    var renderInput = function (_a) {
        var _b;
        var inputProps = _a.inputProps, isOpen = _a.isOpen, isLoading = _a.isLoading;
        return (React.createElement("div", { onClick: isMobile ? inputProps.onFocus : null, className: cn(css.location, (_b = {},
                _b[css.arrival] = type === LocationType.Arrival,
                _b)) },
            React.createElement("span", { className: css.label }, t(type === LocationType.Departure ? 'From' : 'To')),
            !isMobile && (React.createElement("input", __assign({}, inputProps, { className: cn(css.input, className, IGNORE_CLICKOUTSIDE_CLASS), autoComplete: "one-time-code" }))),
            React.createElement(Value, { className: css.value, type: type, isOpen: isOpen || (!isMobile && !location), isLoading: isLoading, location: location, inputValue: inputProps.value, withMultiCity: withMultiCity }),
            onSwap && (!isOpen || !isLoading) && (React.createElement("button", { type: "button", className: css.switcher, tabIndex: -1, onClick: onSwapHandler }, switcherIcon))));
    };
    return React.createElement(LocationComponent, __assign({}, props, { renderInput: renderInput }));
};
export default Location;
