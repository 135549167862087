import { __assign, __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { subSeconds, addSeconds, differenceInMilliseconds, formatISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useOrderQuery, useResetOrderPaymentBlockMutation } from '@websky/graphql';
import { getOrder } from '@websky/core/src/Checkout/store/order/selectors';
import { fillOrder } from '@websky/core/src/Checkout/store/order/actions';
import { setOrder } from '@websky/core/src/Modules/Exchange/store/order/actions';
import { get, set } from '@websky/core/src/cache';
import { format } from '@websky/core/src/utils';
import RootPaymentForm from '@websky/core/src/PaymentForm';
import SimpleLoader from '@websky/core/src/SimpleLoader';
import css from './PaymentForm.css';
var errorIcon = (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM9 15V13H11V15H9ZM9 5V11H11V5H9Z", fill: "#E02222" })));
var MIN_TIME_LIMIT_IN_MINUTES = 10;
var PAYMENT_TIME_LIMIT = 'PAYMENT_TIME_LIMIT';
var PaymentForm = function (props) {
    var _a;
    var t = useTranslation('Exchange').t;
    var dispatch = useDispatch();
    var order = useSelector(getOrder);
    var orderId = order.id;
    var history = useHistory();
    var match = useRouteMatch();
    var _b = __read(React.useState(false), 2), isLoading = _b[0], setIsLoading = _b[1];
    var refetchOrder = useOrderQuery({
        fetchPolicy: 'no-cache',
        skip: true
    }).refetch;
    var _c = __read(useResetOrderPaymentBlockMutation(), 1), resetOrderPayment = _c[0];
    var paymentMethods = [
        props.credit,
        props.card,
        props.itransfer,
        props.googlePay,
        props.kaspi,
        props.cash,
        props.invoice,
        props.qiwi,
        props.sbol,
        props.fps
    ];
    var baseUrl = match.url;
    var isExchange = baseUrl.includes('exchange');
    var paymentId = React.useMemo(function () {
        var _a, _b;
        if (isExchange) {
            return (_b = (_a = order.exareInfo) === null || _a === void 0 ? void 0 : _a.lastExchange) === null || _b === void 0 ? void 0 : _b.id;
        }
        return order.id;
    }, [order, isExchange]);
    var timeLimitSessionKey = "".concat(PAYMENT_TIME_LIMIT, "_").concat(paymentId);
    var timeLimitFromSession = get(timeLimitSessionKey);
    var timeLimitInSeconds = timeLimitFromSession || ((_a = paymentMethods.find(function (method) { return !!method; })) === null || _a === void 0 ? void 0 : _a.timelimitInSeconds);
    React.useEffect(function () {
        if (!timeLimitFromSession) {
            set(timeLimitSessionKey, timeLimitInSeconds);
        }
    }, []);
    var timeLimit = React.useMemo(function () {
        var _a, _b;
        if (isExchange) {
            return (_b = (_a = order.exareInfo) === null || _a === void 0 ? void 0 : _a.lastExchange) === null || _b === void 0 ? void 0 : _b.timelimit;
        }
        return order.timelimit;
    }, [order, isExchange]);
    var minTimeLimitInSeconds = React.useMemo(function () {
        if (!timeLimitInSeconds) {
            return undefined;
        }
        var minTimeLimit = MIN_TIME_LIMIT_IN_MINUTES * 60;
        if (timeLimitInSeconds < minTimeLimit) {
            // time limit slightly shorter to redirect the user to the order in advance
            return timeLimitInSeconds * 0.85;
        }
        return minTimeLimit;
    }, [timeLimitInSeconds]);
    var timeLimitDate = React.useMemo(function () {
        if (!timeLimit) {
            return undefined;
        }
        var timeLimitDate = new Date(timeLimit);
        if (!minTimeLimitInSeconds) {
            return timeLimitDate;
        }
        var paymentStartDate = subSeconds(timeLimitDate, timeLimitInSeconds);
        return addSeconds(paymentStartDate, minTimeLimitInSeconds);
    }, [timeLimit, timeLimitInSeconds, minTimeLimitInSeconds]);
    var redirectUrl = React.useMemo(function () {
        if (isExchange) {
            return baseUrl.replace('payment', 'request');
        }
        return baseUrl.replace('payment', 'order');
    }, [baseUrl, isExchange]);
    var onFillOrder = React.useCallback(function (order) {
        if (isExchange) {
            dispatch(setOrder(order));
        }
        else {
            dispatch(fillOrder(order));
        }
    }, [isExchange, dispatch]);
    var onBackToOrder = React.useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, resetOrderPayment({ variables: { id: orderId } })];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, refetchOrder({ id: orderId })];
                case 3:
                    data = (_a.sent()).data;
                    if (!(data === null || data === void 0 ? void 0 : data.Order)) {
                        return [2 /*return*/];
                    }
                    onFillOrder(data.Order);
                    history.push(redirectUrl);
                    localStorage.removeItem(timeLimitSessionKey);
                    return [3 /*break*/, 5];
                case 4:
                    e_1 = _a.sent();
                    console.log(e_1);
                    return [3 /*break*/, 5];
                case 5:
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, [orderId, timeLimitSessionKey, redirectUrl, resetOrderPayment, refetchOrder, onFillOrder]);
    React.useEffect(function () {
        var timeoutId;
        if (timeLimitDate !== undefined) {
            var millisecondsUntilResetPayment = differenceInMilliseconds(timeLimitDate, new Date());
            timeoutId = setTimeout(onBackToOrder, millisecondsUntilResetPayment);
        }
        return function () {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [timeLimitDate]);
    return (React.createElement(React.Fragment, null,
        isLoading && React.createElement(SimpleLoader, null),
        isExchange && (React.createElement("div", { className: css.timelimit },
            errorIcon,
            t('Order must be paid by {{time}} on {{date}}', {
                time: format(timeLimitDate, 'HH:mm'),
                date: format(timeLimitDate, 'd MMMM yyyy')
            }))),
        React.createElement(RootPaymentForm, __assign({}, props, { order: props.order
                ? __assign(__assign({}, props.order), { timelimit: timeLimitDate ? formatISO(timeLimitDate) : props.order.timelimit }) : null }))));
};
export default PaymentForm;
