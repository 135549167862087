import cn from 'classnames';
import * as Results from './components/Results/Results.css';
import * as FareGroup from './components/FareGroup/FareGroup.css';
import * as DesktopFlight from './components/DesktopFlight/DesktopFlight.css';
import * as QuickSearchForm from './components/QuickSearchForm/QuickSearchForm.css';
import * as WeekCalendar from './components/WeekCalendar/WeekCalendar.css';
import * as SearchForm from './components/SearchForm/SearchForm.css';
import * as Datepicker from './components/Datepicker/Datepicker.css';
import * as PriceGraph from './components/PriceGraph/PriceGraph.css';
import * as DesktopFlightInfo from './components/DesktopFlightInfo/DesktopFlightInfo.css';
import * as DesktopSummaryFlightInfo from './components/DesktopSummaryFlight/DesktopSummaryFlight.css';
import * as SelectedFares from './components/SelectedFares/SelectedFares.css';
import * as Stepbar from './components/Stepbar/Stepbar.css';
import * as Toolbar from './components/Toolbar/Toolbar.css';
import * as Checkout from './components/Checkout/Checkout.css';
import * as AdditionalServiceBanner from './components/AdditionalServiceBanner/AdditionalServiceBanner.css';
import * as BaggageService from './components/BaggageService/BaggageService.css';
import * as Switch from './components/Switch/Switch.css';
import * as Passenger from './components/Passenger/Passenger.css';
import * as CheckoutOrderSummary from './components/CheckoutOrderSummary/CheckoutOrderSummary.css';
import * as CheckoutAdditionalService from './components/CheckoutAdditionalService/CheckoutAdditionalService.css';
import * as Lang from './components/Lang/Lang.css';
import * as ToggleableFlights from './components/ToggleableFlights/ToggleableFlights.css';
import * as OrderPaid from './components/OrderPaid/OrderPaid.css';
import * as ThanksBanner from './components/ThanksBanner/ThanksBanner.css';
import * as MonthsWrapper from './components/MonthsWrapper/MonthsWrapper.css';
import * as Step from './components/Step/Step.css';
import * as Actions from './components/Actions/Actions.css';
import * as Segment from './components/Segment/Segment.css';
import * as FlightPrice from './components/FlightPrice/FlightPrice.css';
import * as MealService from './components/MealService/MealService.css';
import * as Dialog from './components/Dialog/Dialog.css';
import * as ServiceRecommendation from './components/ServiceRecommendation/ServiceRecommendation.css';
import * as Link from './components/Link/Link.css';
import * as Input from './components/Input/Input.css';
import * as Service from './components/Service/Service.css';
import * as FullScreenDialog from './components/FullScreenDialog/FullScreenDialog.css';
import * as MobileSegmentInfo from './components/MobileSegmentInfo/MobileSegmentInfo.css';
import * as MobileSummaryFlight from './components/MobileSummaryFlight/MobileSummaryFlight.css';
import * as MobileStepbar from './components/MobileStepbar/MobileStepbar.css';
import * as MobileToolbar from './components/MobileToolbar/MobileToolbar.css';
import * as BaggagePage from './components/BaggagePage/BaggagePage.css';
import * as OrderHeader from './components/OrderHeader/OrderHeader.css';
import * as Page404 from './components/Page404/Page404.css';
import * as PromoLoader from './components/PromoLoader/PromoLoader.css';
import * as Tooltip from './components/Tooltip/Tooltip.css';
import * as Sortings from './components/Sortings/Sortings.css';
import * as Info from './components/Info/Info.css';
import * as OrderPaidMobile from './components/OrderPaidMobile/OrderPaidMobile.css';
import * as Account from './components/Account/Account.css';
import * as ServiceDialogStyles from './components/Service/components/Dialog/Dialog.css';
import * as InsuranceStyles from './components/Insurance/Insurance.css';
import * as InsuranceCardStyles from './components/Insurance/InsuranceCard/InsuranceCard.css';
import * as SpecialDocumentFieldStyles from './components/Insurance/InsuranceInner/SpecialDocument/SpecialDocument.css';
import * as ButtonStyles from './components/Button/Button.css';
import * as AircraftPopup from './components/AircraftPopup/AircraftPopup.css';
import * as LoginForm from './components/LoginForm/LoginForm.css';
import * as DesktopFlightRoute from './components/DesktopFlightRoute/DesktopFlightRoute.css';
import * as Exchange from './components/Exchange/Exchange.css';
import * as SeatMap from './components/SeatMap/SeatMap.css';
import * as FlightCard from './components/FlightCard/FlightCard.css';
import * as OrderCard from './components/OrderCard/OrderCard.css';
import * as FareConditions from './components/FareConditions/FareConditions.css';
import * as Modal from './components/Modal/Modal.css';
import * as MobileSelectedFare from './components/MobileSelectedFare/MobileSelectedFare.css';
import * as PromoCode from './components/PromoCode/PromoCode.css';
import * as PaymentForm from './components/PaymentForm/PaymentForm.css';
import * as CompareFares from './components/CompareFares/CompareFares.css';
import * as CoreSchedule from './components/CoreSchedule/CoreSchedule.css';
import * as Weekpicker from './components/CoreSchedule/Weekpicker/Weekpicker.css';
import * as Notification from './components/Notification/Notification.css';
import * as Contacts from './components/Contacts/Contacts.css';
import * as PassengerForm from './components/PassengerForm/PassengerForm.css';
import * as OrderPassengers from './components/OrderCard/components/OrderPassengers/OrderPassengers.css';
import * as SocialAuth from './components/SocialAuth/SocialAuth.css';
var theme = {
    AircraftPopup: {
        AircraftPopup: {
            list: AircraftPopup.list,
            listItem: AircraftPopup.listItem
        },
        AircraftPopupContent: {
            groups_item: AircraftPopup.groups_item,
            header: AircraftPopup.header,
            title: AircraftPopup.title
        }
    },
    LoginPage: {
        Verification: {
            LoginVerification__form: Account.verification__form,
            submit_button: Account.verification__submit
        },
        Form: {
            footer: LoginForm.footer,
            social: cn(Contacts.social)
        }
    },
    Tooltip: {
        Tooltip: {
            tooltip: Tooltip.tooltip
        }
    },
    Lang: {
        LangStyles: {
            lang__selected: Lang.selected,
            dropdown__icon: Lang.icon,
            dropdown: Lang.dropdown,
            lang_opened: Lang.lang_opened
        }
    },
    PromoLoader: {
        PromoLoader: {
            promo: PromoLoader.promo,
            promo__image: PromoLoader.promo__image,
            promo__content: PromoLoader.promo__content,
            promo_inner: PromoLoader.inner,
            wrapper: PromoLoader.wrapper
        }
    },
    Page404: {
        Page404: {
            content: Page404.content,
            button: Page404.button
        }
    },
    SearchForm: {
        Actions: {
            removeSegment: Actions.actions__button,
            addSegment: Actions.actions__button
        },
        MultiCity: {
            caption: SearchForm.multicity__caption
        },
        Location: {
            input_withoutPlaceholder: SearchForm.input_withoutPlaceholder,
            autocomplete_alternative: SearchForm.autocomplete_alternative,
            switcher_alternative: SearchForm.location__switcher_alternative,
            input: SearchForm.location__input,
            switcher: SearchForm.segment__switcher
        },
        Value: {
            value: SearchForm.value__value,
            code: SearchForm.value__code
        },
        SearchForm: {
            searchForm: SearchForm.searchForm,
            row: SearchForm.searchForm__row
        },
        Datepicker: {
            alternative: Datepicker.alternative,
            oneDate: Datepicker.oneDate,
            dates: SearchForm.datepicker__dates,
            dates__back: SearchForm.datepicker__dates_back,
            dates__to: SearchForm.datepicker__dates_to,
            dates_focused: SearchForm.datepicker__dates_focused,
            placeholder: SearchForm.datepicker__placeholder,
            returnDelete: SearchForm.datepicker__returnDelete
        },
        Segment: {
            cell_date: SearchForm.cell_date,
            remover: Segment.remover,
            input: SearchForm.segment__input,
            input_focused: SearchForm.segment__input_focused
        },
        Currency: {
            currency: SearchForm.currency,
            selector: SearchForm.currency_selector,
            arrowIcon: SearchForm.currency_arrow,
            dropdown: SearchForm.dropdown
        },
        Passengers: {
            passengers: SearchForm.passengers,
            selector: SearchForm.passengers__selector,
            selector_focused: SearchForm.passengers__selector_focused,
            arrowIcon: SearchForm.passengers__arrowIcon,
            arrowIcon_flipped: SearchForm.passengers__arrowIcon_flipped
        }
    },
    Passengers: {
        PassengerStyles: {
            counter: Passenger.counter,
            info: Passenger.info,
            passengerType: Passenger.type,
            increase: Passenger.increase,
            decrease: Passenger.decrease,
            description: Passenger.description
        }
    },
    Toolbar: {
        Toolbar: {
            button_disabled: Toolbar.disabled,
            inner: Toolbar.inner,
            button: Toolbar.toolbarButton,
            actions_left: Toolbar.actions__left,
            actions: Toolbar.actions
        }
    },
    Button: {
        Button: {
            loader__spinner: ButtonStyles.loader__spinner
        }
    },
    Stepbar: {
        Step: {
            arrow: Step.arrow
        },
        Stepbar: {
            stepbar: Stepbar.stepbar,
            inner: Stepbar.inner
        }
    },
    SearchFormDatepicker: {
        MonthStyles: {
            month: Datepicker.month
        },
        MonthsWrapperStyles: {
            nav_next: Datepicker.next,
            nav_prev: Datepicker.prev,
            wrapper: Datepicker.monthsWrapper_wrapper
        },
        WeeklyHeaderStyles: {
            dayOfWeekName_withPrices: Datepicker.weekday
        },
        DayStyles: {
            day_highlighted: Datepicker.day_highlighted,
            day_withPrice: Datepicker.day_withPrice,
            day_hasPrice: Datepicker.day_hasPrice,
            day__price: Datepicker.day__price,
            day_inPeriod: Datepicker.day_inPeriod
        },
        SearchFormDatepickerStyles: {
            popup: SearchForm.popup,
            datepicker__disclaimer: Datepicker.disclaimer,
            footer__done: Datepicker.footer__done,
            day: Datepicker.day,
            tab_selected: Datepicker.datepicker__tab_selected,
            content: Datepicker.content,
            tab: Datepicker.datepicker__tab,
            tab__indicator: Datepicker.tab__indicator
        }
    },
    PriceGraph: {
        DayStyles: {
            progress: PriceGraph.day__progress,
            outDate: PriceGraph.outDate,
            backDate: PriceGraph.backDate,
            day: PriceGraph.day
        },
        PriceGraphStyles: {
            graphHeader: Datepicker.graphHeader,
            graphHeader__dates: Datepicker.graphHeader__dates,
            graphHeader__price: Datepicker.graphHeader__price
        },
        MonthsListStyles: {
            currentMonth: Datepicker.monthList__currentMonth
        }
    },
    Datepicker: {
        MonthsWrapperStyles: {
            nav_next: MonthsWrapper.nav_next,
            nav_prev: MonthsWrapper.nav_prev
        },
        DayStyles: {
            day: Datepicker.day,
            day_inPeriod: Datepicker.day_inPeriod,
            day_highlighted: Datepicker.day_highlighted,
            day_selected: Datepicker.day_selected,
            day__inside: Datepicker.day__inside,
            day_startPeriod: Datepicker.day_startPeriod,
            day_endPeriod: Datepicker.day_endPeriod
        }
    },
    AdditionalServiceBanner: {
        AdditionalServiceBannerStyles: {
            content: AdditionalServiceBanner.content,
            baggageBanner: AdditionalServiceBanner.banner_baggage,
            seatBanner: AdditionalServiceBanner.banner_seat,
            mealBanner: AdditionalServiceBanner.banner_meal,
            image: AdditionalServiceBanner.image
        }
    },
    Switch: {
        Switch: {
            label: Switch.label,
            wrapper: Switch.wrapper,
            active: Switch.active
        }
    },
    MealService: {
        MealServiceStyles: {
            segments: MealService.segments
        }
    },
    BaggageService: {
        BaggageServiceStyles: {
            service__name: BaggageService.service__name,
            baggage__icon_notSelected: BaggageService.not_selected,
            baggage__addButton: BaggageService.addButton,
            baggage__addButton_add: BaggageService.addButton_add,
            addButton__icon: BaggageService.addButton__icon,
            segment__direction: BaggageService.segment__direction,
            carryon: BaggageService.carryon,
            services__switcher: BaggageService.services__switcher,
            service__sizes: BaggageService.service__sizes,
            service__values: BaggageService.service__values,
            service__price: BaggageService.service__price,
            baggage: BaggageService.baggage,
            checkbox: BaggageService.checkbox,
            checkbox_active: BaggageService.checkbox_active,
            baggage_included: BaggageService.included,
            baggage_selected: BaggageService.selected,
            service: BaggageService.baggage__service,
            content: BaggageService.content,
            baggage_full: BaggageService.baggage_full
        }
    },
    Results: {
        DummyWeekCalendar: {
            wrapper: Results.dummyCalendar__wrapper,
            item: Results.dummyCalendar__item,
            item__head: Results.dummyCalendar__head,
            item__middle: Results.dummyCalendar__middle,
            item__footer: Results.dummyCalendar__footer
        },
        Sortings: {
            currentSorting: Sortings.currentSorting,
            switch__label: Sortings.switch__label,
            mobile__list__button: Sortings.mobile__list__button,
            mobile__list__button_inactive: Sortings.mobile__list__button_inactive
        },
        MobilePriceGraph: {
            dates: Results.priceGraph__otherDates
        },
        FlightsList: {
            controls__wrapper: Results.sortings__wrp,
            leg: Results.flightsList__leg
        },
        Results: {
            toolbar__nextButton: Results.toolbar__nextButton,
            selectFare__button: Results.selectFare,
            inner: Results.inner,
            outer: Results.results__outer,
            outer_bottom: Results.outer__bottom,
            messages: Results.messages
        },
        Disclaimer: {
            wrapper: Results.disclaimer__wrapper
        },
        Calendar: {
            title: Results.title,
            title__text: Results.title__text,
            tab: Results.calendar__tab,
            header: Results.calendar__header,
            content: Results.calendar__content,
            legNumber: Results.legNumber,
            diagram: Results.diagramButton
        },
        SelectedFares: {
            flight: Results.selectedFares__flight,
            title: SelectedFares.title
        }
    },
    FareLock: {
        FareLock: {
            root: Results.fareLock__root
        }
    },
    WeekCalendar: {
        DayStyles: {
            day: WeekCalendar.day,
            day_selected: WeekCalendar.selected,
            price: WeekCalendar.day__price,
            day_best: WeekCalendar.day_best
        }
    },
    QuickSearchForm: {
        QuickSearchForm: {
            form: QuickSearchForm.form,
            form__wrapper: QuickSearchForm.form__wrapper,
            outer: QuickSearchForm.outer,
            wrapper: QuickSearchForm.wrapper,
            inner__summary: QuickSearchForm.inner__summary,
            promoCode: QuickSearchForm.quickSearchForm__promoCode
        },
        Summary: {
            route: QuickSearchForm.route,
            passengers: QuickSearchForm.passengers
        }
    },
    FareGroup: {
        FareGroup: {
            fareGroup_white: FareGroup.fareGroup__white,
            title: FareGroup.fareGroup__title,
            info: FareGroup.infoIcon,
            option__tooltip: FareGroup.tooltip,
            option: FareGroup.option,
            price: FareGroup.price,
            fareGroup_Business: FareGroup.fareGroup_business,
            fareGroup_Economy: FareGroup.fareGroup_economy,
            keyFeature__value: FareGroup.value,
            fareGroup: FareGroup.fareGroup,
            keyFeatures: FareGroup.keyFeatures,
            keyFeature: FareGroup.keyFeature,
            options: FareGroup.options,
            button: FareGroup.fareGroup__button,
            option__icon: FareGroup.fareGroup__optionIcon,
            option__title: FareGroup.fareGroup__optionTitle,
            option__value: FareGroup.fareGroup__optionValue
        },
        RecommendedBadge: {
            badge: FareGroup.badge,
            wrapper: FareGroup.badge__wrapper,
            shadow: FareGroup.badge__shadow,
            inner: FareGroup.badge__inner
        },
        FareGroupGrid: {
            option: FareGroup.fareGroupGrid__option,
            icon: FareGroup.fareGroupGrid__icon
        }
    },
    FlightPrice: {
        FlightPrice: {
            price: FlightPrice.price,
            wrapper: FlightPrice.flightPrice__wrapper,
            wrapper_business: FlightPrice.wrapper_business,
            wrapper_active: FlightPrice.active,
            prefix: FlightPrice.flightPrice__prefix
        }
    },
    DesktopFlight: {
        ModalContent: {
            header_title: DesktopFlight.desktopFlight__headerTitle,
            paper: DesktopFlight.modalContent_paper,
            fareGroup_stub: DesktopFlight.fareGroup_stub,
            close: DesktopFlight.desktopFlight__close,
            close_icon: DesktopFlight.desktopFlight__closeIcon,
            fareGroup__button: DesktopFlight.desktopFlight__button,
            fareGroup__button_active: DesktopFlight.desktopFlight__buttonActive,
            compareFaresLink: DesktopFlight.desktopFlight__compareFaresLink,
            compareFaresLink_icon: DesktopFlight.desktopFlight__compareFaresLinkIcon,
            scroll_up: DesktopFlight.desktopFlight__scrollUp,
            segments: DesktopFlight.desktopFlight__segments,
            slider__wrp: DesktopFlight.slider__wrp,
            slider__wrp_singleItem: DesktopFlight.slider__wrp_singleItem,
            fareGroup__slide: DesktopFlight.fareGroup__slide
        },
        Header: {
            flightInfo__col_singleFare: DesktopFlight.col_singleFare,
            flightInfo__col__head: DesktopFlight.col__head,
            flightInfo__inner: DesktopFlight.inner,
            flightInfo__col_duration: DesktopFlight.col_duration,
            flightInfo__col_details: DesktopFlight.col_details,
            col: DesktopFlight.col,
            flightInfo__col__sub: DesktopFlight.col_sub,
            flightInfo__col_time: DesktopFlight.col_time,
            date: DesktopFlight.date,
            date_singleFare: DesktopFlight.date_singleFare
        },
        Flight: {
            flight: DesktopFlight.flight,
            fareGroups: FareGroup.fareGroups
        },
        DesktopFlightPopup: {
            paper: DesktopFlight.desktopFlightPopup_paper
        },
        Location: {
            terminal: DesktopFlight.desktopFlight__locationTerminal,
            time: DesktopFlight.desktopFlight__locationTime,
            time_iata: DesktopFlight.desktopFlight__locationTimeIata
        }
    },
    FlightCard: {
        FlightCard: {
            aircraft_return: FlightCard.aircraft_return,
            aircraft__image: FlightCard.aircraft__image,
            airline: FlightCard.flightCard__airline,
            airline__logo: FlightCard.flightCard__airlineLogo,
            airline__number: FlightCard.flightCard__airlineNumber,
            flight__block: FlightCard.flightCard__flightBlock,
            flight__title: FlightCard.flightCard__flightTitle,
            flight__value: FlightCard.flightCard__flightValue,
            footer: FlightCard.flightCard__footer,
            aircraft__name_active: FlightCard.flightCard__aircraftNameActive,
            info: FlightCard.flightCard__info
        }
    },
    DesktopFlightRoute: {
        DesktopFlightRouteStyles: {
            container: DesktopFlightRoute.container,
            flight: DesktopFlightRoute.flight
        },
        SegmentStyles: {
            route__time: DesktopFlightRoute.route__time,
            planeInfo: DesktopFlightRoute.segment__planeInfo,
            plane_return: Results.plane_return,
            aircraft: DesktopFlightRoute.segment__aircraft,
            amenities: DesktopFlightRoute.segment__amenities
        },
        SingleFlightSegment: {
            info__aircraft: DesktopFlightRoute.single__aircraft
        },
        FlightPriceStyles: {
            price: DesktopFlightRoute.price,
            price__money: DesktopFlightRoute.price__money,
            button: DesktopFlightRoute.button
        },
        PointStyles: {
            time: DesktopFlightRoute.single__time,
            date: DesktopFlightRoute.single__date,
            date__container: DesktopFlightRoute.pointDate__container,
            airport: DesktopFlightRoute.point__airport,
            point_arrival: DesktopFlightRoute.point_arrival
        },
        RouteArrowStyles: {
            timeInRoute: DesktopFlightRoute.single__timeInRoute
        }
    },
    Amenities: {
        Amenities: {
            amenities: DesktopFlightInfo.amenities,
            amenities__item: DesktopFlightInfo.amenities_available,
            amenities__item_unavailable: DesktopFlightInfo.amenities_unavailable
        }
    },
    DesktopFlightInfo: {
        Segment: {
            plane: DesktopFlightInfo.segment__plane,
            airline: DesktopFlightInfo.segment__airline,
            aircraft__name__link: DesktopFlightInfo.segment__link,
            weather: DesktopFlightInfo.weather,
            flight: DesktopFlightInfo.flight,
            points: DesktopFlightInfo.points,
            weather__temperature: DesktopFlightInfo.weather__temperature,
            duration: DesktopFlightInfo.duration
        },
        Point: {
            airportName: DesktopFlightInfo.airportName,
            terminal: DesktopFlightInfo.terminal,
            point: DesktopFlightInfo.point
        },
        DesktopFlightInfo: {
            footer: DesktopFlightInfo.flight__footer
        }
    },
    DesktopSummaryFlight: {
        Header: {
            header__cell: DesktopSummaryFlightInfo.header__cell
        },
        SummaryFlight: {
            flight: DesktopSummaryFlightInfo.flight
        }
    },
    ServiceRecommendation: {
        Seats: {
            header: ServiceRecommendation.seats__header
        }
    },
    Autocomplete: {
        Autocomplete: {
            autocomplete__root: Input.autocomplete__root,
            focused: Input.focused,
            label_error: Input.label_error,
            input: 'test'
        }
    },
    PhoneInput: {
        PhoneInput: {
            input: Input.input_phone
        }
    },
    Input: {
        Input: {
            disabled: Input.disabled,
            textField: Input.textField,
            textField__root: Input.textField__root,
            label_shrink: Input.label_shrink,
            label_error: Input.label_error,
            input: Input.input
        }
    },
    MobileFlight: {
        FlightStyles: {
            flight_withAircraft: MobileSegmentInfo.flight_withAircraft,
            segments: MobileSegmentInfo.segments,
            flight: Results.flight,
            price: Results.flight__price
        },
        Info: {
            fareType_active: Info.active,
            fareType_economy: Info.fareType_economy,
            fareType_business: Info.fareType_business
        }
    },
    Link: {
        Link: {
            link: Link.link
        }
    },
    Checkout: {
        Transaction: {
            wrapper: OrderPaidMobile.transaction__wrapper
        },
        ThanksBanner: {
            wrapper: ThanksBanner.wrapper
        },
        Services: {
            baggageService__rules: Checkout.baggageService__rulesLink
        },
        CheckoutStyles: {
            selectedFlight__container: Checkout.selectedFlight__container,
            summary: Checkout.summary,
            summary__header: Checkout.header,
            container: Checkout.container,
            summary__wrp: Checkout.summary__wrp,
            passengers__header: Checkout.passengers__header,
            container_seats: Checkout.container__seats,
            detailsModal__header: Checkout.detailsModal__header,
            form: Checkout.passengers__form,
            checkoutServices: Checkout.checkoutServices,
            paidOrderWrapper: Checkout.checkout_paidOrderWrapper,
            checkout_narrow: PaymentForm.checkout__checkoutNarrow,
            messages: Checkout.checkout_messages
        },
        OrderPaid: {
            buttons__checkin: OrderPaid.order_paid__button,
            order__card: OrderPaid.order__card
        },
        ToggleableFlights: {
            flight: ToggleableFlights.flight,
            flight_noMargin: ToggleableFlights.flight_noMargin,
            planeIcon: ToggleableFlights.planeIcon,
            flights: ToggleableFlights.flights,
            wrapper__button: ToggleableFlights.wrapper,
            button: Checkout.toggleFlights
        },
        PassengersToolbar: {
            label: Checkout.label,
            terms: Checkout.checkout_terms
        }
    },
    Passenger: {
        PassengerStyles: {
            passenger: Passenger.passenger,
            header__services: Passenger.passenger__headerServices
        }
    },
    OrderSummary: {
        OrderHeader: {
            manage: OrderHeader.manage,
            button__download: OrderHeader.download
        }
    },
    CheckoutOrderSummary: {
        OrderSummary: {
            price__total: CheckoutOrderSummary.price__total,
            wrapper: CheckoutOrderSummary.wrapper,
            flight: CheckoutOrderSummary.flight,
            price: CheckoutOrderSummary.price,
            view: CheckoutOrderSummary.view,
            details: CheckoutOrderSummary.details,
            timelimit: CheckoutOrderSummary.timelimit
        }
    },
    CheckoutAdditionalService: {
        CheckoutAdditionalServiceBaggage: {
            baggage: CheckoutAdditionalService.baggage
        },
        Insurance: {
            insurance: CheckoutAdditionalService.insurance
        },
        Seats: {
            seats: CheckoutAdditionalService.seats
        },
        CheckoutAdditionalService: {
            add: CheckoutAdditionalService.addButton,
            header__text: CheckoutAdditionalService.header__text,
            content: CheckoutAdditionalService.content,
            header__price: CheckoutAdditionalService.header__price,
            service: CheckoutAdditionalService.service,
            service_active: CheckoutAdditionalService.active,
            header: CheckoutAdditionalService.header,
            main: CheckoutAdditionalService.main
        },
        Loyalty: {
            loyalty: CheckoutAdditionalService.loyalty
        },
        PriorityBoarding: {
            priority: CheckoutAdditionalService.priority
        }
    },
    Service: {
        ServiceStyles: {
            service_hasProducts: Service.service_hasProducts,
            service_canAdd: Service.service_canAdd,
            totalPrice: Service.service__totalPrice
        },
        DialogStyles: {
            dialog__header: ServiceDialogStyles.dialog__header,
            header: ServiceDialogStyles.header,
            popupHeader: ServiceDialogStyles.popupHeader,
            paper: ServiceDialogStyles.paper,
            dialog__content: Dialog.dialog__content,
            dialog__button_done: Dialog.button_done,
            dialog__button_clear: Dialog.button_clear
        }
    },
    Insurance: {
        MobileScreenStyles: {
            header: InsuranceStyles.mobileScreen__header
        },
        InsuranceStyles: {
            rules: InsuranceStyles.rules,
            wrapper: InsuranceStyles.wrapper,
            insurances: InsuranceStyles.insurances,
            controls: InsuranceStyles.controls,
            documents: InsuranceStyles.documents,
            dialog__button_done: InsuranceStyles.dialog__button_done,
            dialog__button_clear: InsuranceStyles.dialog__button_clear
        },
        InsuranceCardStyles: {
            insurance: InsuranceCardStyles.insurance,
            selected: InsuranceCardStyles.selected,
            insurance__header: InsuranceCardStyles.insurance__header,
            insurance__content: InsuranceCardStyles.insurance__content,
            insurance__money: InsuranceCardStyles.insurance__money,
            insurance__button: InsuranceCardStyles.insurance__button,
            insurance__button_selected: InsuranceCardStyles.insurance__button_selected
        },
        SpecialDocumentField: {
            name: SpecialDocumentFieldStyles.name,
            field: SpecialDocumentFieldStyles.field,
            input: SpecialDocumentFieldStyles.input
        }
    },
    MobileSegmentInfo: {
        MobileSegmentInfo: {
            airport__name: MobileSegmentInfo.airport__name,
            airport__city: MobileSegmentInfo.airport__city,
            date: MobileSegmentInfo.date,
            timeEnRoute_text: MobileSegmentInfo.timeEnRoute__text,
            short: MobileSegmentInfo.short,
            details__short: MobileSegmentInfo.details__short,
            details: MobileSegmentInfo.details,
            details__arrow: MobileSegmentInfo.details__arrow,
            segment_filled: MobileSegmentInfo.filled,
            timeEnRoute: MobileSegmentInfo.timeEnRoute
        }
    },
    MobileSelectedFare: {
        MobileSelectedFareStyles: {
            selectedFare: MobileSummaryFlight.mobileSelectedFare,
            dialog__header: MobileSelectedFare.dialog__header
        }
    },
    FullscreenDialog: {
        FullscreenDialog: {
            header: FullScreenDialog.header,
            close: FullScreenDialog.close
        }
    },
    MobileSummaryFlight: {
        MobileSummaryFlight: {
            header: MobileSummaryFlight.header,
            footer: MobileSummaryFlight.footer,
            footer__price: MobileSummaryFlight.price,
            wrapper: MobileSummaryFlight.wrapper,
            body: MobileSummaryFlight.body
        }
    },
    MobileStepbar: {
        MobileStepbarStyles: {
            stepbar: MobileStepbar.stepbar,
            header: MobileStepbar.header
        }
    },
    MobileToolbar: {
        MobileToolbar: {
            wrapper: MobileToolbar.wrapper,
            button_back: MobileToolbar.button_back,
            button_continue: MobileToolbar.button_continue
        }
    },
    BaggagePage: {
        BaggagePage: {
            items: BaggagePage.items,
            passenger: BaggagePage.passenger,
            baggage__passenger: BaggagePage.baggage__passenger
        }
    },
    Exchange: {
        Calendar: {
            day: Exchange.day__day,
            day__inside: Exchange.day__inside,
            day_inside: Exchange.day_inside,
            day_selected: Exchange.day_selected,
            day_start: Exchange.day__startPeriod,
            day_end: Exchange.day__endPeriod,
            day_highlight: Exchange.day_highlight,
            day_startPeriod: Exchange.day__startPeriod,
            day_endPeriod: Exchange.day__endPeriod,
            datepicker__modal: Exchange.datepicker__modal
        },
        Choice: {
            label: Exchange.choice__label,
            Unvoluntary: Exchange.Unvoluntary
        },
        Payment: {
            timelimit: Exchange.timelimit
        }
    },
    PassengerForm: {
        Group: {
            container: PassengerForm.container,
            identity: PassengerForm.identity,
            field_middleName: PassengerForm.field_middleName,
            documents: PassengerForm.documents
        },
        PassengerForm: {
            label_middleName: PassengerForm.label_middleName
        }
    },
    Refund: {
        Refund: {
            prices: Exchange.exchange_prices
        },
        RequestForm: {
            terms: Exchange.requestForm_terms
        }
    },
    SeatMap: {
        AirplaneStyles: {
            airplane_return: SeatMap.airplane_return,
            airplane__image: SeatMap.airplane__image
        },
        SeatTooltipStyles: {
            details__seatName: SeatMap.seatTooltip__seatName
        },
        SeatStyles: {
            number: SeatMap.number,
            seat_free: SeatMap.free,
            seat: SeatMap.seat,
            seat_unexistent: SeatMap.unexistent
        },
        SeatMapStyles: {
            row__rowPartWrp: SeatMap.rowPartWrp,
            row__PartWrpWithTechFacilities: SeatMap.rowPartWrpWithTechFacilities
        },
        SeatMapDisclaimerStyles: {
            disclaimer: SeatMap.seatMap_disclaimer
        }
    },
    OrderCard: {
        OrderCard: {
            container: OrderCard.orderCard_container,
            type_review: OrderCard.orderCard_typeReview,
            openOrder: OrderCard.orderCard_openOrder
        },
        OrderPreview: {
            inner: OrderCard.orderPassengers_inner,
            overlay: OrderCard.orderPreview_overlay
        },
        CommonItem: {
            root: OrderCard.commonItem_root,
            payload: OrderCard.commonItem_payload
        },
        OrderStatus: {
            root: OrderCard.orderStatus_root,
            type_review: OrderCard.orderStatus_typeReview,
            transactions: OrderCard.orderStatus_transactions,
            confirmed: OrderCard.orderStatus_confirmed,
            icon: OrderCard.orderStatus_icon
        },
        OrderBooking: {
            download_wrapper: OrderCard.orderBooking_downloadWrapper
        },
        OrderPassengers: {
            bottomDrawer: OrderPassengers.bottomDrawer,
            root: OrderCard.orderPassengers_root
        },
        OrderTickets: {
            link: OrderCard.orderTickets_link
        },
        PaymentStatus: {
            root: OrderCard.paymentStatus_root,
            account: OrderCard.paymentStatus_account,
            icon: OrderCard.paymentStatus_icon
        },
        PassengersModal: {
            passengers_wrapper: OrderCard.passengersModal_passengersWrapper
        }
    },
    FareConditions: {
        FareConditions: {
            paperRoot: FareConditions.paperRoot,
            tabsWrp: FareConditions.tabsWrp,
            tab: FareConditions.tab,
            tab_active: FareConditions.tab_active,
            segmentTabs: FareConditions.segmentTabs,
            header: FareConditions.header,
            heading: FareConditions.heading,
            fareConditionsSegmentHeading__inner: FareConditions.fareConditionsSegmentHeading__inner,
            fareConditionsSegmentHeading: FareConditions.fareConditionsSegmentHeading,
            fareConditionsSegmentFareCode: FareConditions.fareConditionsSegmentFareCode,
            fareConditionsContent: cn(FareConditions.fareConditionsContent, Results.fareConditions__content)
        }
    },
    Modal: {
        Modal: {
            modal: Modal.modal,
            closeIcon: cn(Modal.closeIcon, Contacts.closeIcon),
            scrollBody: Modal.scrollBody
        }
    },
    Contacts: {
        ContactsStyles: {
            footer__scrollBody: Contacts.footer__scrollBody,
            footer__slideBottom: Contacts.footer__slideBottom
        }
    },
    PromoCode: {
        PromoCode: {
            promoCode: PromoCode.promoCode__container,
            clear: PromoCode.promoCode__clear,
            icon: PromoCode.promoCode__icon,
            icon_notApplied: PromoCode.promoCode__iconNotApplied
        },
        PromoCodeForm: {
            button: PromoCode.promoCodeForm__button
        }
    },
    PaymentForm: {
        PaymentForm: {
            wrapper: PaymentForm.paymentForm__wrapper,
            cards_header: PaymentForm.cards_header
        },
        Card: {
            iframe: PaymentForm.iframe
        }
    },
    CompareFares: {
        CompareFares: {
            fareHeadingItem: CompareFares.fareHeadingItem
        }
    },
    CoreSchedule: {
        DateSwitcher: {
            selector: CoreSchedule.selector
        },
        Weekpicker: {
            header__day: Weekpicker.header__day,
            months__wrapper: Weekpicker.months__wrapper,
            day__day: Weekpicker.day__day,
            day__today: Weekpicker.day__today,
            day__selected: Weekpicker.day__selected,
            day__start: Weekpicker.day__start,
            day__end: Weekpicker.day__end,
            day__inPeriod: Weekpicker.day__inPeriod,
            day__inside: Weekpicker.day__inside,
            highlightDate: Weekpicker.highlightDate
        }
    },
    Notification: {
        Notification: {
            wrapper: Notification.wrapper,
            context: Notification.context
        }
    },
    SocialAuth: {
        SocialAuth: {
            wrapper: SocialAuth.wrapper
        }
    },
    WarningModal: {
        WarningModal: {
            header: Modal.warningModal_header
        }
    }
};
export default theme;
