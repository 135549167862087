import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@websky/core/src';
import PassengersToolbarCheckbox from './PassengersToolbarCheckbox/PassengerToolbarCheckbox';
import RequestFormLabel from './RequestFormLabel/RequestFormLabel';
import CloseButton from '../theme/components/FullScreenDialogCloseButton/FullScreenDialogCloseButton';
import ExchangeRequest from './ExchangeRequest/ExchangeRequest';
import CheckoutSelectedFlight from './CheckoutSelectedFlight/CheckoutSelectedFlight';
import OrderBooking from './OrderCard/OrderBooking/OrderBooking';
import OrderStatus from './OrderCard/OrderStatus/OrderStatus';
import PassengersRoute from './PassengersRoute/PassengersRoute';
import CheckoutRetrieveBooking from './CheckoutRetrieveBooking/CheckoutRetrieveBooking';
import SearchFormLocation from './SearchForm/Location/Location';
import SearchFormPassengers from './SearchForm/Passengers/Passengers';
import SearchFormDatepickerValue from './SearchForm/Datepicker/Value/Value';
import SearchFormSegmentDatepicker from './SearchForm/Datepicker/Datepicker/Datepicker';
import SearchFormCurrency from './SearchForm/Currency/Currency';
import BaggageTotalPriceBlock from './Baggage/BaggageTotalPriceBlock/BaggageTotalPriceBlock';
import PaymentForm from './Checkout/PaymentForm/PaymentForm';
import getCustomAutocompletePassengerLabel from './PassengerForm/getCustomAutocompletePassengerLabel';
import getCustomUserCardHeaderLabel from './UserCard/getCustomUserCardHeaderLabel';
export var scatRenderers = {
    ExchangeErrorText: function () {
        var t = useTranslation('Exchange').t;
        return (React.createElement(React.Fragment, null,
            t('Please select another date or modify your search. If you wish to purchase a ticket for this particular date, please contact customer support.'),
            React.createElement(Link, { action: 'mailto:websales@scat.kz»' }, "websales@scat.kz")));
    },
    renderPassengersToolbarLabel: PassengersToolbarCheckbox,
    renderRequestFormLabel: RequestFormLabel,
    renderFullScreenDialogClose: CloseButton,
    renderExchangeRequest: ExchangeRequest,
    renderPassengersRoute: PassengersRoute,
    renderDesktopSummaryHeaderPrice: function () { return null; },
    renderCheckoutSelectedFlight: CheckoutSelectedFlight,
    renderOrderCardBooking: OrderBooking,
    renderOrderCardStatus: OrderStatus,
    CheckoutRetrieveBooking: CheckoutRetrieveBooking,
    SearchFormSegmentDatepicker: SearchFormSegmentDatepicker,
    BaggageTotalPriceBlock: BaggageTotalPriceBlock,
    CheckoutPaymentForm: PaymentForm,
    ExchangePaymentForm: PaymentForm,
    getCustomAutocompletePassengerLabel: getCustomAutocompletePassengerLabel,
    getCustomUserCardHeaderLabel: getCustomUserCardHeaderLabel
};
export var searchFormRenderers = {
    SearchFormLocation: SearchFormLocation,
    SearchFormPassengers: SearchFormPassengers,
    SearchFormDatepickerValue: SearchFormDatepickerValue,
    SearchFormCurrency: SearchFormCurrency,
    SearchFormSegmentDatepicker: SearchFormSegmentDatepicker,
    renderOrderCardStatus: OrderStatus
};
