import i18n from 'i18next';
import { TravellerTypes } from '@websky/core/src/Traveller/types';
import { getPassengerNames } from '@websky/core/src/utils';
var getCustomUserCardHeaderLabel = function (profile, travellerType) {
    var _a = getPassengerNames(profile), firstName = _a.firstName, lastName = _a.lastName;
    if (travellerType === TravellerTypes.CompanionEmpty) {
        return i18n.t('Account:Add companion');
    }
    if (firstName && lastName) {
        return "".concat(lastName, " ").concat(firstName);
    }
    return i18n.t('Account:Welcome!');
};
export default getCustomUserCardHeaderLabel;
